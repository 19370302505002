import type { NextPage } from "next";
import { useLocalStorage } from "../libs/hooks/useLocalStorage";
import { USER_ROLE } from "../typedef";
import { useEffect } from "react";
import { useRouter } from "next/router";

const Home: NextPage = () => {
  const [value] = useLocalStorage<USER_ROLE | null>("role", null);

  const router = useRouter();

  useEffect(() => {
    if (value === "admin") {
      router.push("/content/makeup");
    } else {
      router.push("/partner");
    }
  }, [value]);
  return <></>;
};

export default Home;
